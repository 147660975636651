import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link } from 'react-router-dom';
import { useFormik } from "formik";

import { forgetPwdStart, getCaptchaError } from '../../redux/user/userActions';
import { selectError, selectFetching } from '../../redux/user/userSelectors';
import {
  getCaptcha
} from '../../services/authenticationServices';

import {
  validateEmail,
  validatePassword,
} from '../../utils/validation';

import Button from '../Button/Button';
import TextButton from '../Button/TextButton/TextButton';
import Divider from '../Divider/Divider';
import Card from '../Card/Card';
import FormInput from '../FormInput/FormInput';

const ForgetPwdCard = ({ getCaptchaError, forgetPwdStart, error, fetching }) => {
  const [captcha, setCaptcha] = useState('');
  const [isCaptchaDisabled, setIsCaptchaDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  // get captcha function
  const handleCaptchaFetch = async(e) => {
    e.preventDefault();
    if (isCaptchaDisabled) return;

    try {
      const { email, fullName } = formik.values;
      const response = await getCaptcha(email, null);
      setCaptcha('code');

      setIsCaptchaDisabled(true);
      setCountdown(5);
    } catch (err) {
      console.log(err);
      getCaptchaError(err); // Handling errors in obtaining verification codes
    }
  };

  useEffect(() => {
    let intervalId;

    if (countdown >= 0) {
      intervalId = setInterval(() => {
        setCountdown(prevCountdown => Math.max(prevCountdown - 1, 0));
        if (countdown === 0) {
          clearInterval(intervalId);
          setIsCaptchaDisabled(false);
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);

  const validate = (values) => {
    const errors = {};
    const emailError = validateEmail(values.email);
    if (emailError) errors.email = emailError;

    const passwordError = validatePassword(values.password);
    if (passwordError) errors.password = passwordError;

    let passwordConfirmError = validatePassword(values.passwordConfirm);
    passwordConfirmError = values.passwordConfirm === values.password ? false : true;
    if (passwordConfirmError) errors.passwordConfirm = 'The two passwords are not the same';

    const captchaError = values.captcha === '';
    if (captchaError) errors.captcha = 'Verification code is required';
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      captcha: '',
      passwordConfirm: ''
    },
    validate,
    onSubmit: (values) =>
      forgetPwdStart(
        values.email,
        values.password,
        values.captcha
      )
  });

  return (
    <Fragment>
      <Card className="form-card">
        <h1 className="heading-logo text-center">Team up Sport</h1>
        <h2
          style={{ fontSize: '1.7rem' }}
          className="heading-2 color-grey text-center"
        >
          Sign up to see photos and videos from your friends.
        </h2>
        <Divider>OR</Divider>
        {Object.keys(formik.errors).map((field) => {
          if (formik.touched[field]) {
            return (
              <p
                className="error"
                key={formik.errors[field]}
                style={{ marginTop: '0' }}
              >
                {formik.errors[field]}
              </p>
            );
          }
        })}
        <form className="form-card__form" onSubmit={formik.handleSubmit}>
          <FormInput
            name="email"
            fieldProps={formik.getFieldProps('email')}
            valid={formik.touched.email && !formik.errors.email}
            placeholder="Email address"
          />
          <FormInput
            name="password"
            fieldProps={formik.getFieldProps('password')}
            placeholder="Password"
            valid={formik.touched.password && !formik.errors.password}
            type="password"
          />
          <FormInput
            name="passwordConfirm"
            fieldProps={formik.getFieldProps('passwordConfirm')}
            valid={formik.touched.passwordConfirm && !formik.errors.passwordConfirm}
            placeholder="Confirm Password"
            type="password"

          />
          <FormInput
            name="captcha"
            fieldProps={formik.getFieldProps('captcha')}
            valid={formik.touched.captcha && !formik.errors.captcha}
            placeholder="Captcha"
          />
          <Button
            type="button"
            disabled={isCaptchaDisabled}
            onClick={handleCaptchaFetch}
          >
            {isCaptchaDisabled ? `${countdown}s` : 'Get Captcha'}
          </Button>

          <Button
            loading={fetching}
            disabled={
              Object.keys(formik.touched).length === 0 ? true : !formik.isValid
            }
          >
            Reset Password
          </Button>
          <p></p>
        </form>
        <p className="error">
          {error
            ? error
            : formik.submitCount > 0 && Object.values(formik.errors)[0]}
        </p>
        <p className="heading-5 color-grey">
          By signing up, you agree to our Terms . Learn how we collect, use and
          share your data in our Data Policy and how we use cookies and similar
          technology in our Cookies Policy .
        </p>
      </Card>
      <Card>
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem',
          }}
        >
          <h4 style={{ marginRight: '5px' }} className="heading-4 font-thin">
            Have an account?
          </h4>
          <Link to="/login">
            <TextButton medium blue bold>
              Log in
            </TextButton>
          </Link>
        </section>
      </Card>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  forgetPwdStart: (email, password,captcha) =>
    dispatch(forgetPwdStart(email, password,captcha)),
    getCaptchaError: (err) => dispatch(getCaptchaError(err)),
});

const mapStateToProps = createStructuredSelector({
  error: selectError,
  fetching: selectFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPwdCard);