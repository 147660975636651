import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { signUpStart, getCaptchaError } from '../../redux/user/userActions';
import { selectError, selectFetching } from '../../redux/user/userSelectors';
import {
  getCaptcha
} from '../../services/authenticationServices';

import {
  validateEmail,
  validateFullName,
  validateUsername,
  validatePassword,
} from '../../utils/validation';

import Button from '../Button/Button';
import TextButton from '../Button/TextButton/TextButton';
import Divider from '../Divider/Divider';
import Card from '../Card/Card';
import FormInput from '../FormInput/FormInput';

const SignUpCard = ({ signUpStart, error, fetching, getCaptchaError }) => {

  const [captcha, setCaptcha] = useState('');
  const [isCaptchaDisabled, setIsCaptchaDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0); // Initialization countdown is 0 seconds

  // function for getting captcha
  const handleCaptchaFetch = async(e) => {
    e.preventDefault();
    if (isCaptchaDisabled) return; // If the button is disabled, do not obtain the verification code.

    try {
      const { email, fullName } = formik.values;

      const response = await getCaptcha(email, fullName);

      setCaptcha('code');
      setIsCaptchaDisabled(true); // Set button disabled state
      setCountdown(5); // Set countdown to 60 seconds
    } catch (err) {
      console.log(err);
      getCaptchaError(err);
    }
  };

  // The countdown starts when the component is mounted
  useEffect(() => {
    let intervalId;

    if (countdown >= 0) {
      intervalId = setInterval(() => {
        setCountdown(prevCountdown => Math.max(prevCountdown - 1, 0));
        if (countdown === 0) {
          clearInterval(intervalId);
          setIsCaptchaDisabled(false); // Enable button after countdown ends
        }
      }, 1000);
    }

    // Clear timer when component is uninstalled
    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]); // There is only countdown in the dependency array to ensure that the button status is correct after the countdown ends.


  const validate = (values) => {
    const errors = {};
    const emailError = validateEmail(values.email);
    if (emailError) errors.email = emailError;

    const fullNameError = validateFullName(values.fullName);
    if (fullNameError) errors.fullName = fullNameError;

    const usernameError = validateUsername(values.username);
    if (usernameError) errors.username = usernameError;

    const passwordError = validatePassword(values.password);
    if (passwordError) errors.password = passwordError;

    let passwordConfirmError = validatePassword(values.passwordConfirm);
    passwordConfirmError = values.passwordConfirm === values.password ? false : true;
    if (passwordConfirmError) errors.passwordConfirm = 'The 2 passwords are different';

    const captchaError = values.captcha === '';
    if (captchaError) errors.captcha = 'Verification code required';
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      username: '',
      password: '',
      captcha: '', // Initialize captcha
      passwordConfirm: '', // Add confirm password
      userType: 'regular' // Add user type
    },
    validate,
    onSubmit: (values) =>
      signUpStart(
        values.email,
        values.fullName,
        values.username,
        values.password,
        values.captcha,
        values.userType
      ),
  });

  return (
    <Fragment>
      <Card className="form-card">
        <h1 className="heading-logo text-center">Team up Sport</h1>
        <h2
          style={{ fontSize: '1.7rem' }}
          className="heading-2 color-grey text-center"
        >
          Sign up to see photos and videos from your friends.
        </h2>
        <Divider>OR</Divider>
        {Object.keys(formik.errors).map((field) => {
          if (formik.touched[field]) {
            return (
              <p
                className="error"
                key={formik.errors[field]}
                style={{ marginTop: '0' }}
              >
                {formik.errors[field]}
              </p>
            );
          }
        })}
        <form className="form-card__form" onSubmit={formik.handleSubmit}>
          <FormInput
            name="email"
            fieldProps={formik.getFieldProps('email')}
            valid={formik.touched.email && !formik.errors.email}
            placeholder="Email address"
          />
          <FormInput
            name="fullName"
            fieldProps={formik.getFieldProps('fullName')}
            valid={formik.touched.fullName && !formik.errors.fullName}
            placeholder="Full Name"
          />
          <FormInput
            name="username"
            fieldProps={formik.getFieldProps('username')}
            valid={formik.touched.username && !formik.errors.username}
            placeholder="Username"
          />
          <FormInput
            name="password"
            fieldProps={formik.getFieldProps('password')}
            placeholder="Password"
            valid={formik.touched.password && !formik.errors.password}
            type="password"
          />
          <FormInput
            name="passwordConfirm"
            fieldProps={formik.getFieldProps('passwordConfirm')}
            valid={formik.touched.passwordConfirm && !formik.errors.passwordConfirm}
            placeholder="Confirm Password"
            type="password"

          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ display: 'inline-block' }}>
              <input
                type="radio"
                name="userType"
                value="regular"
                onChange={formik.handleChange}
                checked={formik.values.userType === 'regular'}
              />
              Regular User
            </label>
            <label style={{ display: 'inline-block', marginLeft: '20px' }}>
              <input
                type="radio"
                name="userType"
                value="athlete"
                onChange={formik.handleChange}
                checked={formik.values.userType === 'athlete'}
              />
              Athlete
            </label>
          </div>
          <FormInput
            name="captcha"
            fieldProps={formik.getFieldProps('captcha')}
            valid={formik.touched.captcha && !formik.errors.captcha}
            placeholder="Verification code"
          />
          <Button
            type="button"
            disabled={isCaptchaDisabled}
            onClick={handleCaptchaFetch}
          >
            {isCaptchaDisabled ? `${countdown}s` : 'Get verification code'}
          </Button>

          <Button
            loading={fetching}
            disabled={
              Object.keys(formik.touched).length === 0 ? true : !formik.isValid
            }
          >
            Sign Up
          </Button>
          <p></p>
        </form>
        <p className="error">
          {error
            ? error
            : formik.submitCount > 0 && Object.values(formik.errors)[0]}
        </p>
        <p className="heading-5 color-grey">
          By signing up, you agree to our Terms . Learn how we collect, use and
          share your data in our Data Policy and how we use cookies and similar
          technology in our Cookies Policy .
        </p>
      </Card>
      <Card>
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem',
          }}
        >
          <h4 style={{ marginRight: '5px' }} className="heading-4 font-thin">
            Have an account?
          </h4>
          <Link to="/login">
            <TextButton medium blue bold>
              Log in
            </TextButton>
          </Link>
        </section>
      </Card>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signUpStart: (email, fullName, username, password, captcha, userType) =>
    dispatch(signUpStart(email, fullName, username, password, captcha, userType)),
    getCaptchaError: (err) => dispatch(getCaptchaError(err)),
});

const mapStateToProps = createStructuredSelector({
  error: selectError,
  fetching: selectFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCard);
